import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { UiCard } from '../ui'
import useStore from '../stores/useStore'
import { formatCurrency } from '../utils'
import CustomLinkButton from '../pages/done/shared/CustomLinkButton'

import QRCode from 'qrcode.react'

const Content = styled.div`
  margin-bottom: 20px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${props => (props.align ? props.align : 'flex-start')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`

const TypographyStyled = styled(Typography)`
  && {
    font-weight: ${props => (props.weight ? props.weight : 'normal')};
    text-transform: ${props => (props.capitalize ? 'capitalize' : 'none')};
  }
`

const Card = styled(UiCard)`
  && {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
  }
`

const CardQRCode = ({
  children,
  participant: { name, id, document_type, document_value, categoryLabel, categoryId },
  optionals,
}) => {
  const { isTicket, categoryPrice } = useStore()

  const docType = type => {
    switch (type) {
      case 'cpf':
        return 'CPF'
      case 'rg':
        return 'RG'
      default:
        return type
    }
  }

  return (
    <Card>
      <Content>
        {id && (
          <Column align="center" marginBottom="20px">
            <QRCode value={id} />
          </Column>
        )}
        <Column marginBottom="20px">
          <TypographyStyled>Participante</TypographyStyled>
          <TypographyStyled weight="bold">{name}</TypographyStyled>
        </Column>
        <Row marginBottom="20px">
          <Column>
            <TypographyStyled weight="500">{docType(document_type)}</TypographyStyled>
            <TypographyStyled>{document_value}</TypographyStyled>
          </Column>
          {id && (
            <Column>
              <TypographyStyled weight="500">Código da inscrição</TypographyStyled>
              <TypographyStyled>{id}</TypographyStyled>
            </Column>
          )}
        </Row>
        <Row>
          <Column>
            <TypographyStyled weight="500">{isTicket ? 'Ingresso' : 'Inscrição'}</TypographyStyled>
          </Column>
          <Column>
            <TypographyStyled weight="500">Valor</TypographyStyled>
          </Column>
        </Row>
        <Row marginBottom="20px">
          <Column>
            <TypographyStyled>{categoryLabel}</TypographyStyled>
          </Column>
          <Column>
            <TypographyStyled>{formatCurrency(categoryPrice(categoryId))}</TypographyStyled>
          </Column>
        </Row>
        {optionals.length === 0 ? null : (
          <Row>
            <Column>
              <TypographyStyled weight="500">Opcionais</TypographyStyled>
            </Column>
            <Column>
              <TypographyStyled weight="500">Valor</TypographyStyled>
            </Column>
          </Row>
        )}

        {optionals &&
          optionals.map(element => (
            <Row key={element.optionalId}>
              <Column>
                <TypographyStyled>{element.label}</TypographyStyled>
              </Column>
              <Column>
                <TypographyStyled>{formatCurrency(element.amount_in_cents)}</TypographyStyled>
              </Column>
            </Row>
          ))}
      </Content>
      <Row marginBottom="20px">
        <CustomLinkButton />
      </Row>
      {children}
    </Card>
  )
}

export default CardQRCode
